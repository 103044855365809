import React, { useEffect } from "react"
import { Grid, Container, Paper } from "@material-ui/core"
import "./styles.css"
import { Typography } from "instadrops-ui"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import { callAnalyticsEvent } from "../utils"
import { NavbarLayout } from "../layouts"

const WrappedApp = () => {
  return (
    <NavbarLayout>
      <App />
    </NavbarLayout>
  )
}

const App = () => {
  useEffect(() => {
    callAnalyticsEvent("view_privacy_page")
  }, [])

  return (
    <div
      style={{
        minHeight: "90vh",
        paddingTop: 36,
        paddingBottom: 36,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Grid container justify="center">
          <Paper style={{ padding: 12, borderRadius: 18 }} elevation={3}>
            <Typography>
              <ReactMarkdown>
                {`**Política de Privacidad**

Fecha de Actualización: 9 de Junio de 2024.

En Alchtec.com, estamos dedicados a la salvaguarda de su privacidad. La presente Política de Protección de Datos Personales detalla la información que recolectamos de nuestros clientes, cómo la gestionamos, protegemos y los derechos que usted tiene sobre sus datos personales cuando utiliza nuestros servicios de asesoría.

**Recolección de Información**

Recabamos datos personales de nuestros clientes para facilitar la prestación de nuestros servicios de asesoría, que incluyen:

1. Datos de Identificación: Recopilamos su nombre, dirección de correo electrónico y otros datos personales cuando se registra para recibir nuestra asesoría.

2. Datos de Interacción con Servicios: Recolectamos información relativa a las consultas y necesidades específicas que usted nos plantea durante la prestación de nuestros servicios.

**Uso de la Información**

Su información personal se utiliza exclusivamente para ofrecer, mejorar y personalizar los servicios de asesoría:

1. Prestación de Servicios: Su información nos permite entregarle la asesoría solicitada, resolver problemas específicos y ajustar nuestros servicios a sus necesidades.

2. Desarrollo de Servicios: Utilizamos la información para analizar y desarrollar nuevas estrategias de asesoría que mejoren la experiencia de nuestros clientes.

**Control sobre su Información**

Valoramos su derecho a la privacidad y le ofrecemos control sobre sus datos personales:

1. Acceso y Actualización: Usted tiene derecho a acceder a sus datos personales en cualquier momento y corregirlos si encuentra algún error.

2. Retirada de Consentimiento: Puede solicitar la eliminación de su información personal de nuestros registros, lo cual conlleva a la terminación de los servicios de asesoría.

**Seguridad de la Información**

Nos comprometemos a proteger su información personal mediante la implementación de medidas de seguridad robustas para prevenir el acceso no autorizado, la utilización indebida o la divulgación de sus datos.

**Actualizaciones de la Política de Protección de Datos Personales**

Podemos realizar cambios a esta política ocasionalmente para reflejar las mejoras en nuestras prácticas de privacidad. Cualquier actualización será comunicada a través de nuestro sitio web y, si es relevante, mediante notificación directa.

**Cómo Contactarnos**

Si tiene preguntas sobre esta política o desea ejercer sus derechos respecto a su información personal, póngase en contacto con nosotros a través de jorge@alchtec.com.

Al solicitar nuestros servicios de asesoría, usted acepta los términos de esta Política de Protección de Datos Personales. Si no está de acuerdo, le pedimos que no acceda ni utilice nuestros servicios.

Alchtec.com se reserva el derecho de modificar esta política según sea necesario para cumplir con las regulaciones de privacidad y mejorar nuestros estándares de protección de datos. Le recomendamos revisar periódicamente esta política para estar informado sobre cómo protegemos su información.`}
              </ReactMarkdown>
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </div>
  )
}

export default WrappedApp
